.englobe_wrapper {
    overflow: hidden;
    position: relative;
}

.front-page {
    position: relative;

    h2 {
        position: relative;
        font-size: 3.75rem;
        text-align: center;
        margin: 0 0 25px;
        padding-bottom: 30px;

        &:after {
            content: "";
            position: absolute;
            width: 231px;
            height: 18px;
            background: url(/wp-content/themes/plomodiern/assets/src/images/sprite-images/shapes/title_vague.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
}

.btn-home {
    position: relative ;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background: $color-dark;
    border: 1px solid $color-dark;
    box-sizing: border-box;
    border-radius: 5px;
    color: $color-white;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    transition: $duration;

    svg {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 40px;
        height: 40px;
        padding: 12px;
        background: $color-white;
        border: 1px solid $color-dark;
        border-radius: 0 5px 5px 0;
        right: -1px;
        box-sizing: border-box;
        transition: $duration;
    }

    &--empty {
        color: $color-dark;
        background-color: transparent;

        svg {
            background: $color-dark;
            fill: $color-white;
        }

        &:hover {
            background-color: $color-dark;
            color: $color-white;

            svg {
                background-color: transparent;
                fill: $color-dark;
            }
        }
    }

    &:hover {
        background-color: transparent;
        color: $color-dark;

        svg {
            background-color: $color-dark;
            fill: $color-white;
        }

        &.btn-home--empty {
            background-color: $color-dark;
            color: $color-white;

            svg {
                background-color: $color-white;
                fill: $color-dark;
            }
        }
    }
}

//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    .swiper-container {
        height: 580px;
        width: 100%;

        .swiper-slide {
            img {
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
                object-position: top;
            }
        }
        
        .swiper-pagination {
            pointer-events: auto;
            display: flex;
            align-items:center;
            right: calc((100% - 1200px) / 2);
            bottom: 45px;
            justify-content: flex-end;
            z-index: 5;
            width: auto;

            .swiper-pagination-bullet {
                width: 16px;
                height: 16px;
                margin: 0 0 0 25px;
                background: $color-white;
                transition: 0.35s;
                opacity: 1;
                filter: drop-shadow(0px 0px 10px rgba(15, 17, 39, 0.6));

                &.swiper-pagination-bullet-active{
                    width: 9px;
                    height: 9px;
                    background: $color-third;
                }

                &:hover {
                    background: $color-third;
                }
            }
        }
    }

    .slideshow {
        display: inherit;
        position: relative;
        width: 100%;
        height: 100%;

        .container--slideshow {
            position: relative;
            height: 100%;
            z-index: 2;
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
            box-sizing: border-box;
            position: absolute;
            right: 0;
            bottom: 0;
            width: 440px;
            height: 100%;
            padding: 20px 10px 85px 50px;
            color: $color-dark;
        }

        &__shape {
            width: 440px;
            height: 100%;
            position: absolute;
            top: 0;
            right: calc((100% - 1200px) / 2);
            background: url(/wp-content/themes/plomodiern/assets/src/images/sprite-images/shapes/forme_ALU.svg) no-repeat;
            background-size: 650px 580px;

            &:after {
                content: '';
                position: absolute;
                width: 2000px;
                height: 100%;
                top: 0;
                right: -2000px;
                background: linear-gradient(180deg, rgba(129, 200, 186, 0) 0%, #81C8BA 73.44%);
            }
        }

        &__title {
            font-family: $font-family--heading;
            font-weight: $font-weight-medium;
            font-size: 1.875rem;
            line-height: 2.125rem;
            max-width: 250px;
            text-align: right;
        }
    }

    .video-container {
        height: 540px;
        width: 100%;
        clip-path: ellipse(200% 100% at bottom);

        .slideshow {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;

            video,
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }

            img {
                display: none;
            }
        }
    }
}

//======================================================================================================
// Home Actualités
//======================================================================================================
.home-posts {
    position: relative;
    z-index: 1;
    padding: 40px 0 20px;

    .featured-posts {
        &__container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-wrap: wrap;
            height: 570px;
            margin-top: 40px;
        }
        
        &__template {
            margin-top: 40px;
            display: flex;
            justify-content: space-between;

            .btn-home {
                width: 570px;
            }
        }
    }

    .featured-post {
        position: relative;
        border-radius: $border-radius;

        &--big {
            width: 570px;
            height: 570px;
            margin-right: 30px;
            
            .featured-post  {
                &__image-container {
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                }

                &__category {
                    position: absolute;
                    display: flex;
                    align-items: flex-end;
                    top: 0;
                    height: 70px;
                    width: 100%;
                    background: $color-main;
                    padding: 10px 40px;
                    border-radius: 5px 5px 0 0;
                    color: $color-dark;
                    font-weight: $font-weight-semibold;
                    text-transform: uppercase;
                }

                &__content {
                    position: absolute;
                    top: 70px;
                    width: 100%;
                    height: calc(100% - 70px);
                    background: linear-gradient(180deg, #DB9296 0%, rgba(219, 146, 150, 0.85) 36.46%, rgba(219, 146, 150, 0) 78.12%);
                    padding: 0 40px;
                    transition: .35s;

                    &:after {
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 0;
                        top: 0;
                        left: 0;
                        background-color: $color-main;
                        transition: .25s;
                        z-index: 0;
                        border-radius: 0 0 5px 5px;
                        transition: $duration;
                    }
                }

                &__title {
                    margin: 0 0 10px;
                    line-height: 2.125rem;
                    z-index: 1;
                    position: relative;
                }

                &__introduction {
                    font-weight: $font-weight-medium;
                    font-size: 1.125rem;
                    line-height: 1.375rem;
                    z-index: 1;
                    position: relative;
                }
            }

            &:hover {
                color: $color-dark;
                .featured-post  {
                    &__content {
                        &:after {
                            height: 100%;
                        }
                    }
                    &__introduction {
                        color: $color-dark;
                    }
                }
            }
        } 

        &--small {
            display: flex;
            width: 570px;
            height: 170px;
            box-shadow: 0px 0px 10px rgb(129 200 186 / 50%);

            .featured-post  {
                &__image-container {
                    width: 170px;
                    height: 170px;
                    border-radius: 5px 0 0 5px;
                }

                &__category {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    width: 170px;
                    height: 100%;
                    padding: 10px 15px;
                    box-sizing: border-box;
                    background: linear-gradient(180deg, rgba(39, 39, 43, 0) 28.65%, #27272B 100%);
                    text-align: center;
                    color:$color-white;
                    font-weight: $font-weight-bold;
                    font-size: 0.8125rem;
                    line-height: 1rem;
                    text-transform: uppercase;
                    border-radius: 5px 0 0 5px;
                }

                &__content {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 10px 30px;
                }

                &__title {
                    font-size: 1.375rem;
                    line-height: 1.625rem;
                    margin: 0 0 10px;
                    transition: $duration;
                }   
            }

            &:hover {
                box-shadow: 0 0 1px 1px rgb(129 200 186);
                color: $color-dark;
            }
        }

        &__image-container {
            overflow: hidden;
            background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            } 
        }
    }
}

//======================================================================================================
// Home Agenda
//======================================================================================================
.home-events {
    position: relative;
    background: linear-gradient(90deg, #62B9A8 0%, #9DD2C8 100%), #C4C4C4;
    padding: 0 0 70px;

    &:before {
        position: relative;
        content: "";
        display: inline-block;
        background-image: url(/wp-content/themes/plomodiern/assets/src/images/sprite-images/shapes/vague.svg);
        background-repeat: no-repeat;
        width: 100%;
        height: 188px;
        margin: -100px auto 20px;
        background-position: center;
    }

    .featured-events {
        &__container {
            display: flex;
            justify-content: space-between;
        }

        &__template {
            display: flex;
            justify-content: space-between;
            margin-top: 40px;

            .btn-home {
                width: 570px;

                svg {
                    background-color: $color-second;
                }

                &--empty {
                    svg {
                        background-color: $color-dark;
                        fill: $color-second;
                    }
                }

                &:hover {
                    svg {
                        background-color: $color-dark;
                    }

                    &.btn-home {
                        &--empty {
                            svg {
                                background-color: $color-second;
                                fill: $color-dark;
                            }
                        }
                    }
                }
            }
        }
    }

    .featured-event {
        &__link {
            display: flex;
            align-items: center;
            width: 570px;    
            margin-top: 15px;

            &:first-child {
                flex-direction: row-reverse;

                .featured-event { 
                    &__infos {
                        padding: 10px 70px 10px 30px;
                    }

                    &__dates {
                        margin: 0 -30px 0 -40px;
                    }
                }
            }

            &:hover {
                .featured-event{
                    // &__title {
                    //     color: $color-third;
                    // }

                    &__dates {
                        background: $color-third;
                        color: $color-dark;
                        margin: 0 -40px 0 -50px;
                    }
                    
                    &__image-container {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
                &:first-child {
                    .featured-event { 
                        &__dates {
                            margin: 0 -50px 0 -40px;
                        }
                    }
                }
            }
        }

        &__image-container {
            width: 200px;
            height: 260px;
            background: url(/wp-content/themes/noyau/assets/images/icon-events.png) $color-bg--image no-repeat center;
            border-radius: $border-radius;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: $border-radius;
                transform: scale(1);
                transition: $duration;
            }
        }

        &__infos {
            display: flex;
            align-items: center;
            padding: 10px 30px 10px 70px;
            background: rgba(255,255,255,.4);
            border-radius: $border-radius;
            width: 340px;
            height: 160px;
            transition: $duration;
        }

        &__title {
            font-size: 1.375rem;
            line-height: 1.625rem;
            transition: $duration;
        }

        &__dates {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100px;
            height: 100px;
            background: $color-third;
            border-radius: $border-radius;
            margin: 0 -40px 0 -30px;
            z-index: 2;
            font-family: $font-family--heading;
            text-transform: uppercase;
            transition: $duration;
        }

        &__reccurence {
            font-size: 0.8125rem;
        }

        &__date-day {
            font-weight: $font-weight-medium;
            font-size: 2.5rem;
            line-height: 2.75rem;
        }

        &__date-month {
            font-weight: $font-weight-bold;
        }
    }
}

//======================================================================================================
// Home Kiosque
//======================================================================================================
.home-kiosque-map {

    > .container {
        display: flex;
        justify-content: space-between;
    }
}

.home-documents {
    position: relative;

    &__title {
        text-align: left!important;
        margin-top: 50px!important;

        &:after {
            right: auto !important;
        }
    }

    &__container {
        display: flex;
    }

    &__template-btn {
        margin-bottom: 50px;

        .btn-home {
            width: 470px;
            margin-top: 30px;

            &:hover {
                &.btn-home {
                    &--empty {
                        svg {
                            background-color: #efd9de;
                        }
                    }
                }
            }
        }
    }

    .home-document {
        &__container-image {
            width: 170px;
            height: 240px;
            margin-right: 30px;
            background: url(/wp-content/themes/noyau/assets/images/icon-documents.png) $color-bg--image no-repeat center;
            border-radius: $border-radius;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: drop-shadow(0px 0px 10px rgba(239, 221, 217, 0.5));
                border-radius: $border-radius;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            max-width: 270px;

            p {
                display: inline-block;
                margin-bottom: 10px;
            }
        }

        &__title {
            font-size: 1.375rem;
            line-height: 1.625rem;
            margin: 0 0 20px;
        }

        &__btn {
            position: relative;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            margin-top: 15px;
            width: fit-content;
            border-radius: 30px;

            > span {
                position: relative;
                z-index: 1;
            }

            &:after {
                content: '';
                position: absolute;
                width: 0;
                height: 100%;
                left: 15px;
                border-radius: 0 30px 30px 0;
                z-index: 0;
                transition: $duration;
            }

            &--ecouter {
                .home-document__btn-svg {
                    background-color: $color-third;
                }

                &:after {
                    background-color: $color-third;
                }
            }

            &--feuilleter {
                .home-document__btn-svg {
                    background-color: $color-third;
                }

                &:after {
                    background-color: $color-third;
                }
            }

            &--consulter {
                .home-document__btn-svg {
                    background-color: $color-second;
                } 

                &:after {
                    background-color: $color-second;
                }
            }

            &--telecharger {
                .home-document__btn-svg {
                    background-color: $color-white;
                }

                &:after {
                    background-color: $color-white;
                }
            }

            &:hover {
                color: $color-dark;

                &:after {
                    width: 100%;
                }
            }
        }

        &__btn-svg {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 10px;
        }


        &__filesize {
            span {
                text-transform: uppercase;
            }
        }
    }
}


.home-documents__shape {
    width: 650px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url(/wp-content/themes/plomodiern/assets/src/images/sprite-images/shapes/forme_kiosque.svg) no-repeat;
    background-size: 650px 670px;

    &:after {
        content: '';
        position: absolute;
        width: 2000px;
        height: 440px;
        bottom: 0;
        left: -2000px;
        background: linear-gradient(180deg,rgba(219,146,150,0),rgba(219,146,150,.42));
    }
}

.home-map {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        width: 2000px;
        height: 100%;
        top: 0;
        right: -2000px;
        background: #EFD9DE;
    }
    &__btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 170px;
        height: 140px;
        background: $color-white;
        border: 1px solid $color-main;
        box-sizing: border-box;
        border-radius: $border-radius;
        position: absolute;
        bottom: 55px;
        left: -10px;
        z-index: 9;

        .title_vague {
            fill: $color-main;
            width: 75px;
            height: 7px;
            margin: 15px 0 12px;
        }

        span {
            font-weight: $font-weight-bold;
            text-transform: uppercase;
        }

        > svg:first-child {
            width: 53px;
            height: 53px;
            transition: $duration;
        }

        &:hover {
            > svg:first-child {
                width: 43px;
            }
        }
    }
    &__shape {
        height: 552px;
        width: 660px;
        margin-right: -90px;
        z-index: 1;
        position: relative;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    &__shape-white {
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    
    .front-page {
        h2 {
            font-size: 3.125rem;
            padding-bottom: 30px;

            &:after {
                width: 180px;
                height: 14px;
            }
        }
    }
    
    .home-slideshow {
        .swiper-container {
            height: 470px;
        
            .swiper-pagination {
                pointer-events: auto;
                display: flex;
                align-items:center;
                right: calc((100% - 960px) / 2);
            }
        }
        .slideshow {
            &__shape {
                width: 500px;
                right: calc((100% - 960px) / 2);
                background-size: 650px 470px;
            }
        }

        .video-container {
            height: 470px;
        }
    }

    .home-posts {
        .featured-posts {
            &__container {
                height: 460px;
            }
            
            &__template {
                margin-top: 30px;
    
                .btn-home {
                    width: 460px;
                }
            }
        }
    
        .featured-post {    
            &--big {
                width: 460px;
                height: 460px;
                margin-right: 20px;
                
                .featured-post  {
                    &__category {
                        height: 60px;
                        width: 100%;
                        padding: 10px 30px;
                    }
    
                    &__content {
                        top: 60px;
                        height: calc(100% - 60px);
                        padding: 0 30px;
                    }
                }
            } 
    
            &--small {
                width: 460px;
                height: 140px;
    
                .featured-post  {
                    &__image-container {
                        width: 140px;
                        height: 140px;
                    }
    
                    &__category {
                        width: 140px;
                        padding: 10px;
                        font-size: 0.75rem;
                    }
    
                    &__content {
                        padding: 10px 20px;
                    }
    
                    &__title {
                        font-size: 1.125rem;
                        line-height: 1.375rem;
                        margin: 0 0 5px;
                    }   

                    &__introduction {
                        font-size: 0.875rem;
                        line-height: 1.125rem;
                    }
                }
            }
        }
    }  

    .home-events {
        .featured-events {
            &__template {
                margin-top: 30px;
    
                .btn-home {
                    width: 460px;
                }
            }
        }
    
        .featured-event {
            &__link {
                width: 460px;
    
                &:first-child {
                    .featured-event { 
                        &__infos {
                            padding: 10px 60px 10px 20px;
                        }
    
                        &__dates {
                            margin: 0 -40px;
                        }
                    }
                }
            }
    
            &__image-container {
                width: 140px;
                height: 180px;
            }
    
            &__infos {
                padding: 10px 20px 10px 60px;
                width: 300px;
                height: 140px;
            }

            &__dates {
                margin: 0 -40px;
            }
    
            &__reccurence {
                font-size: 0.8125rem;
            }
        }
    }

    .home-documents {
        &__template-btn {
            .btn-home {
                width: 460px;
            }
        }
        &__shape {
            width: 570px;
            background-size: 570px 630px;

            &:after {
                height: 390px;
            }
        }
        .home-document {
            &__btn {
                margin-top: 5px;
            }

            &__container-image {
                width: 150px;
                height: 215px;
                margin-right: 20px;
            }
        }
    }

    .home-map {
        &__shape {
            height: 517px;
            width: 615px;
            margin-right: auto;
            margin-left: 10px;
        }

        &__btn {
            left: 60px;
            bottom: 60px;;
        }
    }
}


// 960
@media screen and (max-width: $medium) {    
    .btn-home span {
        margin-right: 30px;
    }
    .home-slideshow {
        position: relative;

        .swiper-container {
            height: 370px;
        
            .swiper-pagination {
                right: calc((100% - 640px) / 2);
            }
        }
        .slideshow {
            &__shape {
                right: calc((100% - 640px) / 2);
                background-size: 680px 370px;
            }
        }

        .video-container {
            height: 370px;

            .slideshow {
                video {
                    display: none;
                }
                img {
                    display: block;
                }
            }
        }
    }

    .home-posts {
        padding: 50px 0 20px;
    
        .featured-posts {
            &__container {
                height: auto;
            }
            
            &__template {
                margin-top: 10px;
    
                .btn-home {
                    width: 300px;
                }
            }
        }
    
        .featured-post {
            &--big {
                width: 100%;
                height: 545px;
                margin-right: 0;
                margin-bottom: 30px;
            } 
    
            &--small {
                width: 100%;
                height: 170px;
                margin-bottom: 20px;
    
                .featured-post  {
                    &__image-container {
                        width: 170px;
                        height: 170px;
                    }
    
                    &__category {
                        width: 170px;
                        padding: 10px 15px;
                        font-weight: $font-weight-bold;
                        font-size: 0.8125rem;
                        line-height: 1rem;
                    }
    
                    &__content {
                        padding: 10px 30px;
                    }
    
                    &__title {
                        font-size: 1.375rem;
                        line-height: 1.625rem;
                        margin: 0 0 10px;
                    }   
                }
            }
        }
    }

    .home-events {
        padding: 0 0 50px;
        margin-top: -20px;

        &:before {
            height: 178px;
            background-size: 200%;
            margin: -80px auto 20px;
        }
        
        .featured-events {
            &__template {
                margin-top: 30px;
    
                .btn-home {
                    width: 300px;
                }
            }
        }
    
        .featured-event {
            &__link {
                width: 300px;    
                flex-direction: column;
                align-items: flex-start;
    
                &:first-child {
                    flex-direction: column;
                    align-items: flex-end;
    
                    .featured-event { 
                        &__infos {
                            padding: 10px 30px;
                        }

                        &__title {
                            text-align: right;
                        }
    
                        &__dates {
                            margin: -60px 20px -20px 0;
                        }
                    }
                }

                &:hover {
                    .featured-event {
                        &__dates {
                            margin: -60px 0 -20px 20px;
                        }
                    }

                    &:first-child {
                        .featured-event {
                            &__dates {
                                margin: -60px 20px -20px 0;
                            }
                        }
                    }
                }
            }

            &__image-container {
                width: 200px;
                height: 260px;
            }
    
            &__infos {
                padding: 10px 30px;
            }
    
            &__dates {
                margin: -60px 0 -20px 20px;
            }
        }
    }

    .home-kiosque-map {
        > .container {
            flex-direction: column;
        } 
    }
    .home-documents {
        &__shape {
            background-image: none;
            width: 100%;

            &:after {
                height: 90%;
                left: -300px;
                z-index: -1;
            }
        }

        .home-document {
            &__container-image {
                width: 170px;
                height: 240px;
                margin-right: 30px;
            }

            &__btn {
                margin-top: 15px;
            }

            &__content {
                max-width: 100%;
            }
        }

        &__template-btn {
            .btn-home {
                width: 100%;
            }
        }
    }

    .home-map {
        &__btn {
            left: 20px;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .btn-home {
        span {
            padding-right: 25px;
        }
    }

    .home-slideshow {
        .swiper-container {
            clip-path: none;
            height: 450px;

            .swiper-slide {
                img {
                    height: 250px;
                    position: relative;
                }
            }
            .swiper-pagination {
                position: relative;
                margin: auto;
                right: 0;
                left: 0;
                justify-content: center;
                bottom: 45px; 
            }
        }

        .slideshow {
            background: linear-gradient(180deg,rgba(129,200,186,0),#81c8ba 73.44%);

            .container--slideshow {
                height: 200px;
                text-align: center;
                display: flex;
                justify-content: center;
            }

            &__content {
                width: 100%;
                position: relative;
                justify-content: center;
                align-items: center;
                padding: 0 0 30px;
            }

            &__title {
                text-align: center;
            }

            &__shape {
                display: none;
            }
        }

        .video-container {
            height: 450px;

            .slideshow {
                img {
                    height: 250px;
                    position: relative;
                }
                video {
                    position: relative;
                }
            }
        }
    }


    .home-posts {
        .featured-posts {
                       
            &__template {
                flex-direction: column;
                margin-top: 0;
    
                .btn-home {
                    width: 100%;
                    margin-top: 20px;
                }
            }
        }
    
        .featured-post {    
            &--big {
                height: 460px;
                
                .featured-post  {
                    &__category {
                        display: block;
                        padding: 20px;
                        height: 200px;
                    }
    
                    &__content {
                        top: 200px;
                        height: calc(100% - 200px);
                        padding: 0 20px;
                    }

                    &__title {
                        margin-top: -155px;
                        line-height: 1.75rem;
                    }
                }
            } 
    
            &--small {
    
                .featured-post  {
                    &__image-container {
                        display: none;
                    }
    
                    &__category {
                        display: none;
                    }
    
                    &__content {
                        padding: 20px;
                    }
    
                    &__title {
                        font-size: 1.125rem;
                        line-height: 1.375rem;
                        margin: 0 0 5px;
                    }   

                    &__introduction {
                        font-size: 0.875rem;
                        line-height: 1.125rem;
                    }
                }
            }
        }
    }  

    .home-events {  
        margin-top: -30px;

        &:before {
            margin: -80px auto 0px;
        }

        .featured-events {
            &__container {
                flex-direction: column;
            }
    
            &__template {
                flex-direction: column;
                margin-top: 0;
    
                .btn-home {
                    margin-bottom: 20px;
                }
            }
        }
    
        .featured-event {
            &__link {
                align-items: center;  
                margin: 0 auto 40px;
    
                &:first-child {
                    align-items: center;

                    .featured-event { 
                        &__infos {
                            padding: 20px;
                        }

                        &__title {
                            text-align: center;
                        }
    
                        &__dates {
                            margin: -35px auto -20px;
                        }
                    }
                }

                &:hover {
                    .featured-event {
                        &__dates {
                            margin: -35px auto -20px;
                        }
                    }

                    &:first-child {
                        .featured-event {
                            &__dates {
                                margin: -35px auto -20px;
                            }
                        }
                    }
                }
            }
    
            &__infos {
                padding: 20px;
            }

            &__title {
                text-align: center;
            }

            &__dates {
                margin: -35px auto -20px;
            }
        }
    }

    .home-documents {
        &__container {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        &__title {
            text-align: center!important;

            &:after {
                right: 0!important;
            }
        }

        .home-document {
            &__container-image {
                margin: 0 auto 20px;
            }

            &__content {
                align-items: center;
            }

            &__buttons {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
    
    .home-map {
        &__shape {
            margin: auto;
            height: 340px;
            width: 400px;
        }

        &__btn {
            width: 125px;
            height: 105px;
            bottom: 30px;

            span {
                font-size: 0.8125rem;
                line-height: 1rem;
            }

            .title_vague {
                width: 67px;
                height: 5px;
                margin: 11px 0 9px;
            }

            > svg:first-child {
                width: 40px;
                height: 40px;
            }
        }
    }
}
