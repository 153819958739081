.breadcrumb {
    background-color: $color-white;

    ul  {
        display: flex;
        flex-wrap: wrap;
        min-height: 60px;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-text;
        padding: 0;

        // "Vous êtes ici" style
        &:first-child {
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold ;
            text-transform: uppercase;
        }

        svg {
            margin: 0 10px;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .breadcrumb {
        ul {
            padding: 10px 0;
        }
    }

}

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
        } 
    }

}
