header {
    position: relative;
    z-index: 10;

    .shape-ellipse {
        width: 100%;
        height: auto;
        position: absolute;
        bottom: -25px;
        z-index: -1;
    }
}

.header {
    &__container {
        position: relative;
        background-color: $color-white;
    }

    &__identity {
        position: absolute;
        z-index: 11;
        top: 15px;

        &__title {
            font-size: 0;
            line-height: 0;

            img {
                width: 270px;
            }
        }
    }

    &__top {
        z-index: 12;
        position: relative;

        .container--header {
            > svg {
                position: absolute;
                top: 0;
                right: -150px;
                height: 35px;
                width: auto;
                z-index: -1;
            }
        }
    }
}

.header--fixed {
    .header {
        &__bottom {
            position: fixed;
            width: 100%;
            top: 0;
            background-color: $color-white;
        }

        &__identity {
            top: 4px;

            &__title {
                img {
                    width: 185px;
                }
            }
        }
    }

    .tools {
        &__search {
            &.btn-home {
                position: fixed;
                top: 20px;
                border-radius: 50%;
                width: 41px;
                height: 41px;
                border: none;
                padding: 0;
                background: transparent;

                span {
                    display: none;
                }

                svg {
                    border-radius: 50%;
                    width: 41px;
                    height: 41px;
                    padding: 12px 0px;
                }

                &:hover, &:focus {
                    background: transparent;
                }
            }

            &--disabled {
                pointer-events: none;
                opacity: .2;
            }
        }
    }

    .shape-ellipse {
        filter: drop-shadow(0 10px 10px rgba(129, 200, 186, .1));
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

.tools {
    &__content {
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: center;
        justify-content: flex-end;
        width: 100%;
        height: 40px;
        margin-bottom: 25px;
    }

    .tool {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 100%;
        margin: 0 10px;
        padding: 0;
        border: 0;
        border-radius: 0 0 5px 5px;
        
        svg {
            transition: all $duration;
        }

        &:hover, &:focus {
            cursor: pointer;
        }
    }

    &__item--translate {
        background-color: $color-second;
        
        &:hover, &:focus {
            background-color: $color-second;
            height: calc(100% + 10px);
        }

        svg {
            fill: $color-dark;
            width: 16px;
            height: 16px;
        }
    }

    // Google translate
    &__translate {
        position: relative;

        &__wrapper {
            position: absolute;
            left: 50%;
            top: 50px;
            display: none;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
            width: max-content;
            padding: 15px 20px 30px;
            background-color: $color-white;
            border-radius: $border-radius;
            transform: translateX(-50%);
            filter: drop-shadow(0px 0px 1px rgba(27, 35, 54, 0.08)) drop-shadow(1px 1px 10px rgba(27, 35, 54, 0.08));
        
            &.js-open {
                display: flex;
            }

            &::before {
                content: '';
                position: absolute;
                left: 50%;
                top: -6px;
                display: block;
                width: 30px;
                height: 30px;
                background-color: $color-white;
                border-radius: 5px;
                transform: translateX(-50%) rotate(45deg);
                pointer-events: none;
                z-index: -1;
            }

            a.glink {
                position: relative;
                font-family: $font-family;
                font-size: $font-size--text;
                font-weight: $font-weight;
        
                &:hover, &:focus {
                    text-decoration: underline;
                }
        
                &.gt-current-lang {
                    font-weight: $font-weight-bold;
                    text-decoration: underline;
                }
            }
        }

        &__close {
            position: absolute;
            bottom: -10px;
            left: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(-50%);
            padding: 0;
            margin: 0;
            background-color: $color-second;
            border: 0;
            border-radius: $border-radius--round;
            transition: background-color $duration ease-in-out;

            svg {
                width: 26px;
                height: 26px;
                fill: $color-dark;
                transition: fill $duration ease-in-out;
            }

            &:hover, &:focus {
                background-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }
        }
    }

    &__accessibility {
        background-color: $color-third;
        
        &:hover, &:focus {
            background-color: $color-third;
            height: calc(100% + 10px);
        }
        
        svg {
            fill: $color-dark;
            width: 20px;
            height: 18px;
        }
    }

    &__fb {
        background-color: $color-dark;
        
        &:hover, &:focus {
            background-color: $color-dark;
            height: calc(100% + 10px);

            svg {
                fill: $color-white;
            }
        }
        
        svg {
            fill: $color-white;
            width: 8px;
            height: 16px;
        }
    }

    // ADD notifications plugins - 05/06/2024
    &__notifs {
        background-color: $color-dark;
        
        &:hover, &:focus {
            background-color: $color-dark;
            height: calc(100% + 10px);

            svg {
                fill: $color-white;
            }
        }
        
        svg {
            fill: $color-white;
            width: 16px;
            height: 18px;
        }
    }

    &__search {
        background-color: $color-main;

        svg {
            width: 14px;
            height: 14px;
        }

        &.btn-home {
            margin: 0 0 0 10px;
            background-color: $color-main;
            border-color: $color-main;
            width: 185px;
            border-radius: 0 0 5px 5px;
            font-size: 1rem;
            
            svg {
                fill: $color-main;
                transition: $duration;
                border-radius: 0 0px 5px 0;
            }

            span {
                margin-right: 25px;
            }

            &:hover, &:focus {
                background-color: $color-white;
                border-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }
        }

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }
    }
}

.tools-view {
    position: absolute;
    top: 165px;
    width: 100%;
    z-index: 1;

    .container--tools-view {
        display: flex;
        justify-content: flex-end;

        #google_translate_element {
            display: none;
        }

        .profils {
            position: relative;
            display: none;
            width: 240px;
            height: auto;
            margin-top: 45px;
            margin-right: 60px;
            padding: 15px 20px 20px;
            background: $color-second;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__title {
                color: $color-white;
                text-transform: uppercase;
                margin: 20px 0 15px;
            }

            &__list {
                li {
                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 10px 20px 10px 0px;
                        border-top: 1px solid $color-white;
                        color: $color-white;
                        transition: all $duration;

                        &:hover, &:focus {
                            padding-left: 10px;
                        }

                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: $color-white;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }
    }
}

iframe.goog-te-menu-frame.skiptranslate {
    position: fixed;
    top: 75px !important;
    left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 5;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover, &:focus {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 960px)/2 - 335px)!important;
        top: 45px!important;
    }

    .header {
        &__top {
            .container--header {
                > svg {
                    height: 30px;
                }
            }
        }

        &__identity {
            top: 20px; 

            &__title {
                img {
                    width: 220px;
                }
            }
        }
    }

    .tools {
        .tool {
            width: 35px;
            height: 35px;
        }

        &__content {
            margin-bottom: 15px;
        }

        &__search {
            &.btn-home {
                width: 175px;
                height: 35px;   

                svg {
                    width: 35px;
                    height: 35px;
                    padding: 9px;
                }
            }
        }
    }
    header .shape-ellipse {
        bottom: -15px;
    }

    .header--fixed {
        .header {
            &__identity {
                &__title {
                    img {
                        width: 175px;
                    }
                }
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .header__top {
        .container--header {
            > svg {
                height: 27px;
                right: auto;
                left: 150px;
            }
        }
    }

    .header {
        &__identity {
            &__title {
                img {
                    width: 210px;
                }
            }
        }
    }

    .tools {
        &__content {
            margin-bottom: 20px;
        }
    }

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 640px)/2 - 335px)!important;
        top: 45px!important;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    header .shape-ellipse {
        bottom: -13px;
        z-index: 11;
    }
}


// 640
@media screen and (max-width: $small) {

    .tools {
        .container--tools {
            top: -6px;
        }

        &__content {
            justify-content: center;
        }

        .tool {
            margin: 0 10px;
            width: 40px;
            height: 40px;
        }

        &__search {
            &.btn-home {
                width: 40px;
                height: 40px;
                padding: 0;
                margin: 0 10px;
                border-radius: 0 0 5px 5px;

                &:hover, &:focus {
                    background-color: transparent;
                    border-color: transparent;

                    svg {
                        fill: $color-dark;
                        background-color: transparent;
                        border-color: transparent;
                    }
                }

                span {
                    display: none;
                }

                svg {
                    width: 40px;
                    height: 40px;
                    border-radius: 0 0 5px 5px;
                    padding: 11px;
                }
            }

            &:hover, &:focus {
                background-color: transparent;
            }
        }
    }

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute!important;
        left: calc(100% - (100% - 300px/2))!important;
        top: 45px!important;
    }

    .tools-view {
        .container--tools-view {
            .profils {
                margin-top: 80px;
                margin-right: 35px;
            }
        }
    }

    .header {
        &__top {
            .container--header {
                > svg {
                    display: block;
                    text-align: center;
                    margin: auto;
                    left: -350px;
                    right: auto;
                    height: 35px;
                }
            }
        }

        &__identity {
            position: relative;
            width: 100%;
            top: 0;

            &__title {
                display: flex;
                justify-content: center;

                img {
                    width: 270px;
                }
            }
        }

        .shape-ellipse {
            display: none;
        }
    }

}
