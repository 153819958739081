#nav-main {
    //position: relative;
    z-index: 10;
    padding-left: 270px;

    .container--nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        width: 100%;
        transition: $duration ease-out;
    } 

    .nav-main-item {
        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            height: 80px;
            padding: 0 55px;
            color: $color-dark;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            font-size: 1.5rem;
            line-height: 1.625rem;

            > span {
                text-transform: lowercase;
                font-weight: $font-weight-normal;
                display: block;
            }

            &:after {
                content: "";
                position: absolute;
                bottom: 10px;
                left: 0;
                right: 0;
                margin: auto;
                width: 73px;
                height: 6px;
                background-image: url(/wp-content/themes/plomodiern/assets/src/images/sprite-images/shapes/title_vague.svg);
                background-size: contain;
                background-repeat: no-repeat;
                opacity: 0;
                z-index: 9;
                transition: all .25s ease;
            }

            &:before {
                content: "";
                position: absolute;
                background-color: #F7F7F7;
                height: 30px;
                width: 2px;
                top: 12px;
                left: 0;
            }

            &:hover {
                &:after {
                    opacity: 1;
                }
            }
        }

        &:first-child {
            .nav-main-link {
                &:before {
                    display: none;
                }
            }
        }

        &:last-child {
            .nav-main-link {
                padding: 0 55px;
                margin-right: -55px;
            }
        }

        &.active {
            .nav-main-link {
                &:after {
                    opacity: 1;
                }
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            top: 135px;
            width: 100%;
            padding: 0 0 30px;

            &:after {
                content: '';
                width: 5000px;
                height: 100%;
                top: 0;
                left: -2000px;
                position: absolute;
                background: rgba(129, 200, 186, 0.9);
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1), inset 0px 4px 10px rgba(0, 0, 0, 0.1); 
                z-index: -1;
            }

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
                margin-top: 45px;
            }

            .nav-child-item, .nav-grandchild-item {
                width: 100%;
                display: inline-block;
                padding: 0;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    height: 45px;
                    padding: 5px 30px 5px 15px;
                    border: 1px solid $color-white;
                    border-radius: $border-radius;
                    color: $color-text;
                    font-weight: $font-weight-bold;
                    box-sizing: border-box;
                    margin: 5px 0;
                    z-index: 1;

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        position: absolute;
                        right: 7px;
                        color: $color-third;
                        transition: all $duration;
                    }

                    &:hover {
                        color: $color-white;
                        text-decoration: none;
                        background: $color-third;
                        border-color: $color-third;

                        &::after {
                            color: $color-white;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    display: block;
                    padding: 17px 10px 17px 15px;
                    border-bottom: 0;
                    font-size: $font-size--text-small;
                    font-weight: $font-weight;
                    text-transform: initial;

                    &::before {
                        display: none;
                    }
                } 
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px;
    color: $color-dark;

    .navicon {
        position: relative;
        display: block;
        height: 4px;
        top: -0px;
        width: 28px;
        margin-right: 15px;
        background: $color-dark;
        transition: all $duration ease-out;

        &:before, 
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: $color-dark;
            transition: all $duration ease-out;
        }

        &:before {
            top: 8px;
        }

        &:after {
            top: -8px;
        } 
    }
}

.header--fixed {
    #nav-main {
        padding-left: 190px;

        .nav-main-item {
            .nav-main-link {
                height: 85px;
                font-size: 1.375rem;
                line-height: 1.5rem;
                padding: 10px 60px;

                &:before {
                    background-color: $color-second;
                    top: 20px;
                }

                &:after {
                    bottom: 15px;
                }
            }

            &:last-child {
                .nav-main-link {
                    padding: 10px ​60px;
                    margin-right: 60px;
                }
            }

            .sub-menu {
                top: 75px;
            }
        }
    }
}

//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    #nav-main {
        padding-left: 220px;

        .nav-main-item {
            .nav-main-link {
                padding: 0 45px;
                font-size: 1.25rem;
                line-height: 1.5rem;
                height: 70px;
            }

            .sub-menu {
                top: 125px;
            }
        }
    }
    .header--fixed {
        #nav-main {
            .nav-main-item {
                .nav-main-link {
                    padding: 8px 40px;
                    font-size: 1.25rem;
                    
                    &:after {
                        bottom: 19px;
                    }
                }

                &:last-child {
                    .nav-main-link {
                        padding: 8px 40px;
                        margin-right: 35px;
                    }
                }
            }
        }

        .shape-ellipse {
            bottom: -15px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    #nav-main {
        position: relative;
        padding-left: 0;

        .container--nav {
            position: relative;
        }

        .menu__nav__list {
            display: flex;
            flex-flow: column;
            position: absolute;
            top: 55px;
            width: 100%;
            margin: auto -15px;
            padding-top: 20px;

            &:after {
                content: '';
                width: 2000px;
                height: 100%;
                top: 0;
                left: -700px;
                position: absolute;
                background: rgba(129, 200, 186, 0.9);
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1), inset 0px 4px 10px rgba(0, 0, 0, 0.1); 
                z-index: -1;
            }
        }

        .nav-main-item {
            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 100%;
                height: 60px;
                padding: 0 20px;
                border-bottom: 1px solid $color-white;
                color: $color-dark;
                text-transform: uppercase;

                &:after,
                &:before {
                    display: none;
                }
            }

            &:last-child {
                .nav-main-link {
                    border-bottom: none;
                }
            }

            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                .sub-menu {
                    display: none;
                }
            }

            &.active,
            &.menu-item-has-children.active {

                .nav-main-link {
                    color: $color-white;
                    background-color: $color-third;
                }

                .sub-menu {
                    display: flex;
                    position: initial;
                    padding: 0;
                    box-shadow: none;

                    &:after {
                        display: none;
                    }

                    .nav-drop {
                        column-count: 1;
                        margin-top: 0;
                    }

                    .nav-grandchild-item {
                        a {
                            padding: 10px;
                        }
                    }

                    li:last-child {
                        a {
                            &::before {
                                display: none;
                            }

                            &::after {
                                top: 50%;
                                transform: translate(0,-50%);
                            }
                        }
                    }
                }
            }

        }

        // Responsive button
        .nav-main_button {
            ~ .menu {
                clear: both;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition:transform .3s ease;
            }

            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                }
                ~ .nav-main_icon {
                    .navicon {
                        background: $color-bg--transparent;

                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }

                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            height: 60px;
            margin: 0;
            padding: 0 70px 20px 0;
            font-family: $font-family--heading;
            font-size: 1.875rem;
            font-weight: $font-weight-bold;
            text-transform: uppercase; 
        }
    }
}


// 640
@media screen and (max-width: $small) {
    #nav-main {
        .nav-main_icon {
            padding: 0;
            justify-content: center;
        }

        .menu__nav__list {
            top: 60px;
        }
    }
}
