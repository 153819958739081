.bloc-demarche {
    margin-top: 20px;
    border-radius: $border-radius;
    box-shadow: 0px 0px 10px rgba(129, 200, 186, 0.5);

    .accordion--demarche {
        margin: 0;
    }

    &--alone {
        border: 0;
        box-shadow: none;

        .bloc-demarche__content {
            padding: 0;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 30px 30px 30px 140px;
        background: url(/wp-content/themes/noyau/assets/images/ic-comarquage.png) no-repeat $color-second;
        background-position: 30px;
        font-family: $font-family--heading;
        font-size: $font-size--3;
        font-weight: $font-weight-bold;
        line-height: $line-height--heading;
        color: $color-text;
    }

    details {
        summary > svg {
            position: relative;
            z-index: 2;
            top: auto;
            right: auto;
            width: 40px;
            height: 40px;
            margin: 0;
            fill: $color-text;
            transform: rotate(0);
            transition: $duration ease;
        }
    }

    &__content {
        display: block;
        padding: 30px;
        overflow-x: hidden;

        #comarquage {
            margin-top: 5px;

            #co-bar, #co-top, .co-breadcrumb, .news-cat, #co-footer {
                display: none !important;
            }

            #co-page, #co-page.noeud {
                margin-top: 0;
            }
        }
    }

}



//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .bloc-demarche {
        &__title {
            padding: 15px 15px 15px 65px;
            background-position: 15px;
            background-size: 10%;
        }
        &__content {
            padding: 20px;
        }

        #comarquage #co-page .tool-slide button {
            margin-left: 0px;
        }
    }

}