//======================================================================================================
// Actions pages
//======================================================================================================
@keyframes show-hide {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.actions-post {
    display: flex;
    justify-content: center;
    margin: 20px 0;

    li {
        position: relative;

        a, button, div {
            display: flex;
            align-items: center;
            cursor: pointer;
            height: 60px;
            margin: 0;
            padding: 0 30px;
            background-color: transparent;
            border: none;
            border-right: 1px solid rgba(32, 32, 34, 0.1);
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            color: $color-dark;

            svg {
                width: 26px;
                height: 26px;
                margin-right: 14px;
                fill: $color-dark;
            }

            &:hover {
                // opacity: 0.8;
                color: $color-third;

                svg {
                    fill: $color-third;
                }
            }


        }

        &:last-child a, 
        &:last-child button,
        &:last-child div {
            border-right: 0;
        }

    }

    .share-list {
        display: none;
    }

    &.shared-on {
 
        .action-share {
            opacity: 0;
        }

        .share-list {
            display: flex;
            position: absolute;
            top: 10px;
            left: 30px;

            li {
                margin-right: 10px;

                &:first-child {
                    a, button, div {
                        background-color: $color-bg--neutral;
                        transition: background-color ease-in-out $duration;

                        &:hover, &:focus {
                            background-color: $color-main;
                            transition: background-color ease-in-out $duration;
                        }
                    }
                }
            }

            a, button, div {
                
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                width: 40px;
                height: 40px;
                padding: 0;
                background-color: $color-white;
                border: 0;
                border-radius: $border-radius--round;
                box-shadow: $shadow;
                opacity: 1;

                &:hover, &:focus {
                    background-color: $color-white;
                    border: 0;

                    svg {
                        fill: $color-main;
                    }

                }

                svg {
                    margin: 0;
                    transform: scale(1.3);
                }

                &.close {
                    svg {
                        transform: scale(1.5);
                        // stroke: $color-main;
                        fill: $color-main;
                    }
                    &:hover, &:focus {
                        svg {
                            fill: $color-text;
                            // stroke: $color-main;
                        }
                    }
                }

                #post_url {
                    position: absolute;
                    opacity: 0;
                }
            }

            .copy-confirm {
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 35px;
                left: 50%;
                z-index: 2;
                width: 105px;
                height: 30px;
                opacity: 0;
                background: $color-gray;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: none;
                color: $color-white;
                transform: translate(-50%, 0);

                &.show-hide {
                    animation : show-hide 2s;
                }
            }

        }
    }

}


//======================================================================================================
// Responsive 
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .actions-post {
        &.shared-on {
            margin: 60px auto 20px;

            .share-list {
                display: flex;
                position: absolute;
                top: -50px;
                left: -120px;
                filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.15));
            }

            .action-share {
                opacity: 1;
            }

        }
    }

}


// 640
@media screen and (max-width: $small) {

    .actions-post {
        width: 320px;
        margin: 20px auto;

        > li {
            > a, button, div {
                justify-content: center;
                width: 40px;
                height: 40px;
                margin: 0 20px;
                padding: 0;
                background: $color-second;
                border-radius: 50px;

                span {
                    display: none;
                }
                svg {
                    fill: $color-dark;
                    margin: 0;
                }

                &:hover, &:focus, &:active {
                    background: $color-main;
                    opacity: .8;
                    svg {
                        fill: $color-dark;
                    }
                }
            }
        }

        &.shared-on {
            .share-list {
                left:-185px;

                button {
                    margin: 0;

                    svg {
                        fill: $color-main;
                    }
                }
            }
        }

    }
    
}
