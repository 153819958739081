.footer-top {
    position: relative;
    padding-top: 40px;
    margin-bottom: 55px;

    .shape-ellipse {
        filter: drop-shadow(0 5px 5px rgba(129,200,186,0.2));
        width: 100%;
        height: auto;
        position: absolute;
        top: -30px;
        z-index: 5;
        transform: rotate(180deg);
    }

    .footer-infos {
        display: flex;

        &__title {
            font-family: $font-family--heading;
            font-size: $font-size--4;
            font-weight: $font-weight-bold;
            margin: 10px 0 15px;
        }

        &__text {
            margin-bottom: 20px;
        }

        &__phone {
            height: 20px;
            display: flex;
            align-items: center;
            font-weight: $font-weight-bold;

            &:hover {
                color: $color-dark;
                
                svg {
                    fill: $color-second;
                }
            }

            svg {
                fill: $color-main;
                width: 15px;
                height: 15px;
                margin-right: 8px;
            }
        }

        &__btn {
            background-color: $color-second;
            border-color: $color-second;
            width: 300px;
            color: $color-dark;

            &:hover {
                svg {
                    background-color: $color-second;
                    fill: $color-dark;
                }
            }

            svg {
                border-color: $color-second;
            }
        }

        &__wrapper-partners {
            display: flex;
        }

        &__partner-link {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $color-white;
            border: 1px solid #E0E0E0;
            border-radius: $btn-border-radius;
            width: 140px;
            height: 80px;
            padding: 5px 8px;
            box-sizing: border-box;        
            margin: 0 10px;

            &:hover {
                box-shadow: $shadow;
            }
        }

        &__address,
        &__hours {
            flex: 2 1 auto;
        }

        &__partners {
            flex: 0 1 auto;

            .footer-infos {
                &__title {
                    margin-left: 10px;
                }
            }
        }
    }
}

.footer-bottom {
    .container {
        &--footer-bottom {
            position: relative;
        }
    }

    .footer-menu {
        display: flex;
        justify-content: flex-end;
        margin-right: 190px;

        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 15px;
            font-size: 0.8125rem;

            &:hover {
                color: $color-dark;
                text-decoration: underline;
            }
        }
    }

    svg {
        position: absolute;
        bottom: 0px;
        right: -150px;
        z-index: -1;
        width: auto;
        height: 55px;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .footer-top {
        .footer-infos__text {
            max-width: 300px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer-top {
        .footer-infos {
            display: flex;
            flex-wrap: wrap;
            > div {
                flex: auto;
                margin-bottom: 10px;
            }
            &__address {
                order: 1;
                width: 50%;
            }

            &__hours {
                order: 3;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &__partners {
                order: 2;
                width: 50%;
            }
        }

        .shape-ellipse {
            top: -20px;
        }
    } 
    
    .footer-bottom {
        .footer-menu {
            margin: auto;
            justify-content: center;
            flex-wrap: wrap;
            padding-bottom: 5px;

            &__link {
                padding: 5px 20px;
            }
        }

        svg {
            right: -700px;
            height: 70px;
            width: unset;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer-top {
        margin-bottom: 45px;

        .footer-infos {
            &__address {
                width: 100%;
            }

            &__hours {
                order: 2;
                display: block;
            }

            &__partners {
                order: 3;
                .footer-infos{
                    &__title {
                        margin-left: 0;
                    }
                }
            }

            &__partner-link {
                margin: 0;

                &:first-child {
                    margin-right: 20px;
                }
            }
        }
        .shape-ellipse {
            top: 0;
        }
    }
    .footer-bottom {
        .footer-menu {
            flex-wrap: wrap;
            padding-bottom: 14px;

            &__link {
                padding: 5px 10px;
            }
        }

        svg {
            height: 110px;
            right: -1300px;
        }
    }
}