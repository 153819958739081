.cover {
    position: relative;

    &__image-wrapper {
        width: 100%;
        height: 500px;
        background: $color-bg--image;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        background: $color-bg-intro;
        padding: 60px 0 0;
        text-align: center;

        &:after {
            position: relative;
            content: "";
            display: inline-block;
            background-image: url(/wp-content/themes/plomodiern/assets/src/images/sprite-images/shapes/vague.svg);
            background-repeat: no-repeat;
            width: 100%;
            height: 188px;
            margin: 40px auto -70px;
            background-position: center;
            transform: rotate(180deg);
        }
    }

    &__title {
        position: relative;
        color: $color-text;
        margin: 0 0 30px;
        padding-bottom: 40px;

        &:after {
            content: '';
            position: absolute;
            width: 231px;
            height: 18px; 
            background: url(/wp-content/themes/plomodiern/assets/src/images/sprite-images/shapes/title_vague.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    &__lead-wrapper {
        p {
            margin: 20px 0 0;
            font-weight: $font-weight-semibold;
            font-size: $font-size--4;
        }
    }

    &__intro {
        color: $color-text;
    }
}

.single-events .cover__informations:after {
    margin: 40px auto -10px;
}

// > 1200
@media screen and (min-width: $large) {
    section[data-cpt="maps"] .cover__informations {
        padding: 60px 0 70px;
    
        &::after {
            display: none;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .cover {
        &__title {
            font-size: 3.125rem;
            padding-bottom: 30px;
    
            &:after {
                width: 180px;
                height: 14px;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 380px;
        }
        
        &__informations {
            &:after {
                height: 178px;
                background-size: 200%;
                margin: 20px auto -80px;
            }
        }
    }
    
    .single-events .cover__informations:after {
        margin: 20px auto -80px;
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 255px;
        }
        
        &__informations {
            &:after {
                margin: 0px auto -70px;
            }
        }
        
        &__title {
            font-size: 2.35rem;
        }
    }
    
    .single-events .cover__informations:after {
        margin: 0px auto -70px;
    }

}
